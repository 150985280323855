import React, { useState, useEffect } from 'react';
import { ComposableMap, Geographies, Geography } from "react-simple-maps"
import states from './states-10m.json';

const stateNames = {
    "Alabama": "AL",
    "Alaska": "AK",
    "American Samoa": "AS",
    "Arizona": "AZ",
    "Arkansas": "AR",
    "California": "CA",
    "Colorado": "CO",
    "Connecticut": "CT",
    "Delaware": "DE",
    "District Of Columbia": "DC",
    "Federated States Of Micronesia": "FM",
    "Florida": "FL",
    "Georgia": "GA",
    "Guam": "GU",
    "Hawaii": "HI",
    "Idaho": "ID",
    "Illinois": "IL",
    "Indiana": "IN",
    "Iowa": "IA",
    "Kansas": "KS",
    "Kentucky": "KY",
    "Louisiana": "LA",
    "Maine": "ME",
    "Marshall Islands": "MH",
    "Maryland": "MD",
    "Massachusetts": "MA",
    "Michigan": "MI",
    "Minnesota": "MN",
    "Mississippi": "MS",
    "Missouri": "MO",
    "Montana": "MT",
    "Nebraska": "NE",
    "Nevada": "NV",
    "New Hampshire": "NH",
    "New Jersey": "NJ",
    "New Mexico": "NM",
    "New York": "NY",
    "North Carolina": "NC",
    "North Dakota": "ND",
    "Northern Mariana Islands": "MP",
    "Ohio": "OH",
    "Oklahoma": "OK",
    "Oregon": "OR",
    "Palau": "PW",
    "Pennsylvania": "PA",
    "Puerto Rico": "PR",
    "Rhode Island": "RI",
    "South Carolina": "SC",
    "South Dakota": "SD",
    "Tennessee": "TN",
    "Texas": "TX",
    "Utah": "UT",
    "Vermont": "VT",
    "Virgin Islands": "VI",
    "Virginia": "VA",
    "Washington": "WA",
    "West Virginia": "WV",
    "Wisconsin": "WI",
    "Wyoming": "WY",
}

const UtilityMap = ({utilities, territories, highlightedUtility, setFilteredUtilities, instantUtilities, betaUtilities}) => {
    const showState = geo => (e) => {
        const stateName = geo.properties.name;
        const state = stateNames[stateName];
        setFilteredUtilities(utilities.filter(utility => utility.states.includes(state)));
        //setTooltip({ top: e.pageY, left: e.pageX, utilities: utilitiesByState[stateNames[stateName]] });
    };
    return (
        <>
            <ComposableMap projection="geoAlbersUsa">
                <pattern id="instantSelected" patternUnits="userSpaceOnUse" width="8" height="8">
                    <rect width="100%" height="100%" fill="#111B4C" />
                    <rect width="100%" height="100%" fill="#ffffff" fillOpacity="0.3" />
                    <path d="M-2,2 l4,-4
                            M0,8 l8,-8
                            M6,10 l4,-4"
                            style={{stroke: "#111B4C", "strokeWidth": "2"}} />
                </pattern>
                <pattern id="betaSelected" patternUnits="userSpaceOnUse" width="8" height="8">
                    <rect width="100%" height="100%" fill="#E4B644" />
                    <rect width="100%" height="100%" fill="#000000" fillOpacity="0.3" />
                    <path d="M-2,2 l4,-4
                            M0,8 l8,-8
                            M6,10 l4,-4"
                            style={{stroke: "#E4B644", "strokeWidth": "2"}} />
                </pattern>

                <Geographies geography={territories}>
                    {({ geographies }) =>
                        geographies.map((geo) => {
                            let fill = "#e2e6f0";
                            if(geo.properties.utilities) {
                                const highlight = geo.properties.utilities.includes(highlightedUtility);
                                if (instantUtilities.some(item => geo.properties.utilities.includes(item))) {
                                    fill = highlight ? "url('#instantSelected')": "#111B4C";
                                } else if (betaUtilities.some(item => geo.properties.utilities.includes(item))) {
                                    fill = highlight ? "url('#betaSelected')" : "#E4B644";
                                }
                            }
                            return (
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    stroke="#F7F9FC"
                                    strokeWidth="0"
                                    fill={fill}
                                />
                            )
                        })
                    }
                </Geographies>
                <Geographies geography={states}>
                    {({ geographies }) =>
                        geographies.map((geo) => {
                            return <Geography
                                key={geo.rsmKey}
                                geography={geo}
                                stroke="#F7F9FC"
                                strokeWidth="1"
                                fillOpacity={0}
                                onClick={showState(geo)}
                                className="svg-state"
                                onMouseEnter={(e) => {
                                    e.target.parentElement.appendChild(e.target);
                                    e.target.classList.add("svg-state-hover");
                                }}
                                onMouseLeave={(e) => {
                                    e.target.classList.remove("svg-state-hover");
                                }}
                            />
                        })
                    }
                </Geographies>
            </ComposableMap>
        </>
    )
};

export default UtilityMap;