import React, { useState, useEffect } from 'react';
import Fuse from 'fuse.js'
import UtilityMap from './UtilityMap';
import UtilityList from './UtilityList';

const Utilities = () => {
    const [utilities, setUtilities] = useState([]);
    const [territories, setTerritories] = useState([]);
    const [highlightedUtility, setHighlightedUtility] = useState(null);
    const [filteredUtilities, setFilteredUtilities] = useState([]);
    const [time, setTime] = useState("current");

    const currentInstantUtilities = utilities.filter(utility => utility["support_level"] === "instant");
    const currentBetaUtilities = utilities.filter(utility => utility["support_level"] === "beta");

    const juneInstantUtilities = utilities.filter(utility => ["instant", "beta"].includes(utility["support_level"]) || utility["planned_instant_support"] === "June 2025");
    const decemberInstantUtilities = utilities.filter(utility => ["instant", "beta"].includes(utility["support_level"]) || ["June 2025", "December 2025"].includes(utility["planned_instant_support"]));

    const returnStats = (instant, beta) => {
        return {
            instantUtilities: instant.map(utility => utility["symbol"]),
            instant: instant.length,
            instantPopulation: Math.round((instant.map(utility => utility["population"]).reduce((a, b) => a + b, 0) / 145000000) * 100),
            betaUtilities: beta.map(utility => utility["symbol"]),
            beta: beta.length,
            betaPopulation: Math.round((beta.map(utility => utility["population"]).reduce((a, b) => a + b, 0) / 145000000) * 100)
        }
    }

    const stats = {
        current: returnStats(currentInstantUtilities, currentBetaUtilities),
        june: returnStats(juneInstantUtilities, []),
        december: returnStats(decemberInstantUtilities, []),
    }

    const instantUtilities = stats[time]["instantUtilities"];
    const betaUtilities = stats[time]["betaUtilities"];

    useEffect(() => {
        const fetchUtilities = async () => {
            const response = await fetch("https://bayou.energy/api/public/utilities");
            if (!response.ok) {
                throw new Error(`Error loading data from Bayou`);
            }
            const jsonData = await response.json();
            const utilities = jsonData["utilities"];
            setUtilities(utilities.filter(utility => utility["symbol"] !== "speculoos_power"));
        };
        const fetchTerritories = async () => {
            const response = await fetch("https://bayou.energy/api/public/utility_territories");
            if (!response.ok) {
                throw new Error(`Error loading data from Bayou`);
            }
            const territories = await response.json();
            setTerritories(territories);
        };
        fetchUtilities();
        fetchTerritories();
    }, []);
    if (utilities === null) {
        return <div>Loading...</div>
    }
    return (
        <div className="row">
            <div className="col-xl-6 col-lg-7 col-12">
                <h2 className="title">Rapidly expanding utility support</h2>
                <p>You get data instantly from {stats["current"]["instant"]} utilities today and from {stats["june"]["instantPopulation"]}% of US utility customers by June
                    2025.<br />Bayou can add new utilities within one week.</p>
            </div>
            <div className="w-100"></div>
            <div className="col-lg-8 col-12">
                <a href="https://docs.bayou.energy/reference/utility-support"
                    className="btn theme-btn mt-3 me-2">Utility Support
                    <span className="arrow-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none">
                            <path d="M5 12H18M12 5L19 12L12 19" stroke="currentColor" strokeWidth="1.5"
                                strokeLinecap="square" />
                        </svg>
                    </span>
                </a>
                <a href="https://calendly.com/jamesbayouenergy/30min" className="btn theme-btn border-btn mt-3">Request
                    a new utility
                    <span className="arrow-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none">
                            <path d="M5 12H18M12 5L19 12L12 19" stroke="currentColor" strokeWidth="1.5"
                                strokeLinecap="square" />
                        </svg>
                    </span>
                </a>
            </div>
            <div className="col-12 pt-lg-3 pt-0 mt-4">
                <div className="light-padding-box light-bg">
                    <ul className="nav nav-tabs border-bottom mb-0 mt-0" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className={"nav-link" + (time === "current" ? " active" : "")} id="support1-tab" onClick={() => setTime("current")} type="button" role="tab" aria-controls="support1" aria-selected="true">Current</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={"nav-link" + (time === "june" ? " active" : "")} id="support3-tab" onClick={() => setTime("june")} type="button" role="tab" aria-controls="support3" aria-selected="false">June 2025</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={"nav-link" + (time === "december" ? " active" : "")} id="support3-tab" onClick={() => setTime("december")} type="button" role="tab" aria-controls="support3" aria-selected="false">December 2025</button>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div className="tab-pane tab-pane-pad fade show active" role="tabpanel" aria-labelledby="support1-tab">
                            <div className="row gx-xl-5">
                                <div className="col-lg-8">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="card utility-support-card">
                                                <div className="card-body">
                                                    <img src="img/flash_icon.png" className='pull-left me-3' width="46" />

                                                    <span className="utility-support-main">{stats[time]["instant"]}</span> utilities ({stats[time]["instantPopulation"]}% population)<br />
                                                    <span className="utility-support-sub">With instant support</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            {time === "current" &&
                                                <div className="card utility-support-card">
                                                    <div className="card-body">
                                                        <img src="img/beta_icon.png" className='pull-left me-3' width="46" />
                                                        <span className="utility-support-main">{stats[time]["beta"]}</span> utilities ({stats[time]["betaPopulation"]}% population)<br />
                                                        <span className="utility-support-sub">With beta support</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="img-fluid">
                                        <UtilityMap utilities={utilities} territories={territories} highlightedUtility={highlightedUtility} setFilteredUtilities={setFilteredUtilities} instantUtilities={instantUtilities} betaUtilities={betaUtilities} />
                                        <span className="circle-instant"></span> Utilities with instant support
                                        <span className="circle-beta"></span> Beta utilities
                                    </div>
                                </div>
                                <div className="col-lg-4 mt-lg-0 mt-4">
                                    <div className="utility-support-box">
                                        <UtilityList utilities={utilities} filteredUtilities={filteredUtilities} setFilteredUtilities={setFilteredUtilities} highlightedUtility={highlightedUtility} setHighlightedUtility={setHighlightedUtility} instantUtilities={instantUtilities} betaUtilities={betaUtilities} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
};

export default Utilities;









